#coupang-ad-container {
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: 0;
}

.coupang-ad-background {
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 80%;
}

.coupang-ad-popup {
  width: 100%;

  opacity: 100%;
  position: absolute;
  bottom: 0;
}

.coupang-ad-popup-content {
  height: calc(100% - 90px);
  padding: 20px 0;
  background-color: #ffffff;
  border-radius: 12px 12px 0 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.coupang-popup-ad-text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coupang-popup-ad-text-section :first-child {
  font-size: 14px;
  color: #66737f;
  line-height: 20px;
}

.coupang-popup-ad-text-section :last-child {
  font-size: 22px;
  color: #ff0000;
  line-height: 32px;
  font-weight: 600;
}

.coupang-ad-popup-btn-section {
  height: 48px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coupang-ad-popup-btn-section :first-child {
  color: #66737f;
}

.coupang-ad-popup-btn-section :last-child {
  color: #ff0000;
}
