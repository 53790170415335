@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}
dl,
dt,
dd,
ol,
ul,
p,
form,
fieldset,
legend,
h1,
h2,
h3,
h4,
h5,
h6,
pre {
  display: block;
  padding: 0;
  margin: 0;
}
ol,
ul {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-decoration: none;
  font-weight: normal;
}
form,
fieldset,
legend,
button {
  border: none;
}
button:disabled {
  cursor: not-allowed;
}
a {
  text-decoration: none;
}
html,
pre {
  font-family: 'Noto Sans KR', sans-serif;
}
