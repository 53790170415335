#conpick-headline {
  display: flex;
  flex-direction: column;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#conpick-headline .conpick-icon {
  position: absolute;
  top: 20px;
  left: 18px;
  width: 40px;
  height: 21px;
}

#conpick-headline-title {
  margin-top: auto;
  padding: 0 18px;
  font-weight: 500;
  font-size: 20px;
  line-height: 146%;
  color: #ffffff;
}

#conpick-headline-info {
  margin: 6px 18px 18px;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #ffffff;
  opacity: 0.8;
}

#conpick-headline-date {
  margin-left: 8px;
}

#conpick-news-category {
  font-weight: 500;
  font-size: 16px;
  line-height: 136%;
  color: #212121;
  padding: 14px 18px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
}

#conpick-news .conpick-news-list .conpick-news-summary {
  display: flex;
  gap: 12px;
  text-decoration: none;
  padding: 10px 18px;
}

#conpick-news .conpick-news-list .conpick-news-summary .conpick-news-info {
  flex: 2;
}

#conpick-news .conpick-news-list .conpick-news-summary .conpick-news-info .conpick-news-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  color: #212121;
  margin-bottom: 19px;
}

#conpick-news .conpick-news-list .conpick-news-summary .conpick-news-info .conpick-news-media {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #9e9e9e;
  opacity: 0.8;
}

#conpick-news .conpick-news-list .conpick-news-summary .conpick-news-info .conpick-news-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #9e9e9e;
  opacity: 0.8;
  margin-left: 6px;
}

#conpick-news .conpick-news-list .conpick-news-summary .conpick-news-image {
  flex: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
