header#conpick-headline .conpick-logo-icon {
  height: 21px;
}
header#conpick-headline #conpick-headline-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 136%;
  letter-spacing: -0.4px;
  color: #212121;
  margin: 10px 0;
}
header#conpick-headline #conpick-headline-info #conpick-media {
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #9e9e9e;
  margin-right: 8px;
}
header#conpick-headline #conpick-headline-info #conpick-datestamp {
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #9e9e9e;
}

/* 구글 광고 (1) */
#conpick_Mtop_j_336_280 {
  margin-bottom: 12px;
}

/* 메인 */
main {
  margin-bottom: 12px;
}
main img {
  width: 100%;
}
main .imgBox {
  padding-top: 12px;
}
main .imgBox #captionBox p.p_caption {
  font-weight: 400;
  font-size: 14px;
  line-height: 174%;
  color: #9e9e9e;
  padding: 12px 18px;
}
main .imgBox #captionBox span {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 174%;
  color: #9e9e9e;
  padding: 12px 18px;
}
main .conpick-line {
  font-weight: 400;
  font-size: 17px;
  line-height: 174%;
  color: #212121;
  padding: 8px 18px;
}
main .strong {
  font-weight: 600;
}
main.coupang-layer {
  position: relative;
  height: 0;
  padding-bottom: 67%;
  overflow: hidden;
}
main.coupang-layer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 75%,
    #ffffff 100%
  );
}
